export type AppConfig = {
  API_BASE_URL: string;
  MY_ACCOUNT_BASE_URL: string;
};

export type RegionConfig = {
  PASSWORD_AND_TWO_FA: string;
  COMMUNICATION_PREFERENCES: string;
  CHANGE_CONTACT_DETAILS: string;
  CHANGE_COMMUNICATION_PREFERENCES: string;
  HELP_ARTICLES: string;
  HELP_LINK: string;
};

export enum Region {
  AU = 'AU',
  NZ = 'NZ',
}
