import { ArrowRightIcon, Heading, Text, Box } from '@myob/myob-widgets';
import { TileWrapper } from './styles';

export type TileProps = {
  title: string;
  description: string;
  image: string;
  link: string;
  linkText: string;
  className?: string;
  onClick: (url: string, title?: string) => void;
  disabled?: boolean;
};

const Tile: React.FC<TileProps> = ({ disabled, onClick, className, title, description, image, link, linkText }) => {
  const handleClick = (link: TileProps['link'], linkText: TileProps['linkText']) => {
    !disabled && onClick(link, linkText);
  };

  return (
    <TileWrapper className={className}>
      <Box
        display={'flex'}
        flexDirection={'column'}
        backgroundColor={'backgroundDefault'}
        borderRadius={'md'}
        padding={'md'}
        boxShadow={'strong'}
      >
        <div className="tile-info">
          <img className="tile-info-image" src={image} alt={`${title} tile image`} />
          <div className="tile-info-content">
            <Heading level={'2'} className="tile-info-content__title">
              {title}
            </Heading>
            <Text className="tile-info-content__description">{description}</Text>
          </div>
        </div>
        <a
          tabIndex={0}
          className={`tile-link ${disabled ? 'disabled' : ''}`}
          role="button"
          onClick={() => handleClick(link, linkText)}
          onKeyDown={() => handleClick(link, linkText)}
        >
          <span>{linkText}</span>
          <i className="tile-link__icon">
            <ArrowRightIcon color="brand" />
          </i>
        </a>
      </Box>
    </TileWrapper>
  );
};

export default Tile;
