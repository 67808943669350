import { Button, Card, HelpIcon, PageHead, PageState } from '@myob/myob-widgets';
import somethingWentWrong from 'assets/images/somethingWentWrong.svg';
import { nzConfig, auConfig } from 'config';
import { helper } from '@my-account/tools';
import { Region } from '../../configs/type';
import { ErrorComponentWrapper } from './style';
import React from 'react';

export type Props = {
  pageTitle: string;
  size?: string;
};

export const ErrorComponent: React.FC<Props> = ({ pageTitle, size = '' }) => {
  const helpLink = helper.getRegionFromTimezone() === Region.NZ ? nzConfig.HELP_LINK : auConfig.HELP_LINK;
  const handleClick = () => {
    window.open(helpLink, '_blank');
  };
  return (
    <ErrorComponentWrapper data-testid="somethingWentWrong" data-size={size} className="error-component">
      <PageHead title={pageTitle} />
      <Card>
        <PageState
          title="Something went wrong"
          description={[
            'Try reloading this page.',
            <p key="contact support">If the issue persists, contact MYOB support.</p>,
          ]}
          actions={[
            <Button key={1} type="link" icon={<HelpIcon />} onClick={handleClick}>
              Contact our support team
            </Button>,
          ]}
          image={<img src={somethingWentWrong} alt="Something Went Wrong" />}
        />
      </Card>
    </ErrorComponentWrapper>
  );
};
