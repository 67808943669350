import React, { useEffect, useState, useContext } from 'react';
import { BaseTemplate, Button, Heading, ThemeProvider } from '@myob/myob-widgets';
import { ga, Region } from '@my-account/tools';
import { AccountSelectContextValue, AccountSelectContext } from '@my-account/account';
import { TileList } from './TileList';
import { ContentWrapper, HeaderWrapper, HelpLinksWrapper, SupportRequestWrapper } from './styles';
import { HelpLinks } from './HelpLinks';
import { SupportRequest } from './RequestList/SupportRequest';
import { navigateToUrl } from 'single-spa';
import { HttpStatus } from '../../helpers/request';
import Forbidden from '../../components/Forbidden';
import ErrorComponent from '../../components/ErrorComponent';
import { trackPageViewed } from 'helpers/telemetry';
import { checkSoloAccount, isNewUnoDesignEnabled } from '../../helpers/query';

export const Dashboard: React.FC = () => {
  type UserProfileData = { firstName: string; lastName: string };
  const handleStorage = () => {
    const data: UserProfileData = JSON.parse(localStorage.getItem('USER_PROFILE'));
    setUserFirstName(data.firstName);
  };

  const [userFirstName, setUserFirstName] = useState<string>('');
  const [region, setRegion] = useState<Region>();
  const {
    selected: selectedAccount,
    error: accountSelectError,
    isSoloAccount,
  } = useContext<AccountSelectContextValue>(AccountSelectContext);

  useEffect(() => {
    try {
      ga.push({
        event: 'contentViewed',
        elementId: 'Granted',
      });
      trackPageViewed('Home');
    } catch (e) {}
  }, []);

  useEffect(() => {
    handleStorage();
  });

  useEffect(() => {
    window.addEventListener('localstorage_user_profile_change', handleStorage);
    return () => window.removeEventListener('localstorage_user_profile_change', handleStorage);
  }, []);

  const handleOnClick = () => {
    navigateToUrl('/account/support/support-requests');
  };

  useEffect(() => {
    setRegion(selectedAccount?.region);
  }, [selectedAccount]);

  if (accountSelectError) {
    return accountSelectError.status === HttpStatus.Forbidden ? (
      <BaseTemplate>
        <Forbidden pageTitle="" title="You don’t have access to the page" description="" />
      </BaseTemplate>
    ) : (
      <BaseTemplate>
        <ErrorComponent pageTitle="" />
      </BaseTemplate>
    );
  }

  const theme = checkSoloAccount(isSoloAccount) && isNewUnoDesignEnabled() ? 'solo' : 'classic';

  return (
    <ThemeProvider theme={theme}>
      <BaseTemplate containerClassName="my-account-dashboard" className={`my-account-${theme}`}>
        <div>
          <HeaderWrapper>
            <Heading level="1" className="first_name" data-testid="dashboard-title">
              <span>Welcome, {userFirstName}</span>
            </Heading>
            <div className="account-description">
              Your home for managing your account, billing and product settings.
            </div>
          </HeaderWrapper>
        </div>
        <ContentWrapper>
          <TileList clientId={selectedAccount.clientId} />
          <HelpLinksWrapper>
            <HelpLinks region={region} clientId={selectedAccount.clientId} />
          </HelpLinksWrapper>
        </ContentWrapper>
        {!checkSoloAccount(isSoloAccount) && (
          <SupportRequestWrapper>
            <h3 className="support-request">Support requests</h3>
            <SupportRequest />
            <Button tone="brand" className="view-all-requests" onClick={handleOnClick} label="View all requests">
              View all requests
            </Button>
          </SupportRequestWrapper>
        )}
      </BaseTemplate>
    </ThemeProvider>
  );
};
