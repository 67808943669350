import styled from '@emotion/styled';

export const ErrorComponentWrapper = styled.div`
  &[data-size='small'] {
    .flx-page-state {
      padding-top: 2rem;
      padding-bottom: 1.5rem;
    }
    .flx-page-state__image {
      width: 15rem;
    }
  }
`;
