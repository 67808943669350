import React from 'react';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { AccountSelectWrapper } from '@my-account/account';
import { Dashboard } from 'pages/Dashboard/Dashboard';
import { Route, Switch } from 'react-router-dom';

export enum ERoutePath {
  Account = '/account',
}

export const Routes = () => {
  return (
    <CacheProvider
      value={createCache({
        key: 'my-account-dashboard',
      })}
    >
      <Switch>
        <Route exact path={ERoutePath.Account}>
          <AccountSelectWrapper>
            <Dashboard />
          </AccountSelectWrapper>
        </Route>
      </Switch>
    </CacheProvider>
  );
};
