import React, { useEffect, useState } from 'react';
import PremiumSupportTileItem from './SupportTileItem/PremiumSupportTileItem';
import StandardSupportTileItem from './SupportTileItem/StandardSupportTileItem';

export type TileProps = {
  className?: string;
  onClick: (url: string) => void;
};

const SupportTileItem: React.FC<TileProps> = ({ onClick, className }) => {
  const [premiumUser, setPremiumUser] = useState<boolean>(localStorage.getItem('isPremiumCustomer') === 'true');

  useEffect(() => {
    setPremiumUser(localStorage.getItem('isPremiumCustomer') === 'true');
  }, [localStorage.getItem('isPremiumCustomer')]);

  return premiumUser ? (
    <PremiumSupportTileItem onClick={onClick} className={className} />
  ) : (
    <StandardSupportTileItem onClick={onClick} className={className} />
  );
};

export default SupportTileItem;
