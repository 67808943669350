import { AppConfig, RegionConfig } from './type';

export const appConfig: AppConfig = {
  API_BASE_URL: 'https://home.sit.myaccount.myob.com',
  MY_ACCOUNT_BASE_URL: 'https://sit.myaccount.myob.com',
};

export const auConfig: RegionConfig = {
  COMMUNICATION_PREFERENCES: 'https://my.myob.co.nz/Bd/pages/ContactEdit.aspx?',
  HELP_ARTICLES: 'https://www.myob.com/au/support',
  PASSWORD_AND_TWO_FA: 'https://sit.myaccount.myob.com/account/security',
  CHANGE_CONTACT_DETAILS: 'https://sit.myaccount.myob.com/account/contact-details',
  CHANGE_COMMUNICATION_PREFERENCES: 'https://sit.myaccount.myob.com/account/communication-preferences',
  HELP_LINK: 'https://www.myob.com/au/support/contact-us',
};
export const nzConfig: RegionConfig = {
  COMMUNICATION_PREFERENCES: 'https://my.myob.co.nz/Bd/pages/ContactEdit.aspx?',
  HELP_ARTICLES: 'https://www.myob.com/nz/support',
  PASSWORD_AND_TWO_FA: 'https://my.account.myob.com',
  CHANGE_CONTACT_DETAILS: 'https://sit.myaccount.myob.com/account/contact-details',
  CHANGE_COMMUNICATION_PREFERENCES: 'https://sit.myaccount.myob.com/account/communication-preferences',
  HELP_LINK: 'https://www.myob.com/nz/support/contact-us',
};
