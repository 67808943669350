export enum RoutePath {
  Home = '/',
  PaymentProfile = '/paymentProfile',
  MyBills = '/myBills',
  Product = '/productManagement',
}

export enum AccountViewRoutePath {
  MyBills = '/account/billing/invoices',
  AccountDetails = '/account/manage-account/account-details',
  PaymentDetails = '/account/billing/payment-details',
  Product = '/account/products',
  Support = '/account/support/contact-support',
  ScheduleSupportSession = '/account/support/priority-support-booking',
}
