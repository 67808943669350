import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AccountSelectContext, AccountSelectContextValue } from '@my-account/account';
import { AppThunkDispatch, RootState } from '../../../stores';
import { fetchOpenSupportRequestsAsync, RequestListState } from './reducer';
import { Label, Table } from '@myob/myob-widgets';
import { STATUS, tableColumns } from './constants';
import { SupportRequestInfo, SupportType } from '../type';
import moment from 'moment-timezone';
import { NoData } from './NoData/NoData';
import LoadingWrapper from '../../../components/LoadingWrapper';
import { navigateToUrl } from 'single-spa';
import { HttpStatus } from '../../../helpers/request';
import Forbidden from '../../../components/Forbidden';
import ErrorComponent from '../../../components/ErrorComponent';
import { GtmManager, helper } from '@my-account/tools';
import { Region } from '../../../configs/type';

export const SupportRequest: React.FC = () => {
  const dispatch: AppThunkDispatch = useDispatch();
  const [columns] = useState(tableColumns);
  const { selected: selectedAccount } = useContext<AccountSelectContextValue>(AccountSelectContext);

  const {
    fetch: {
      isLoading,
      isError,
      data: requestsInfo,
      error: { status },
    },
  } = useSelector<RootState, RequestListState>((state) => state.requestList);

  useEffect(() => {
    dispatch(fetchOpenSupportRequestsAsync(selectedAccount?.clientId));
  }, [selectedAccount]);

  const timeTransformer = (time) => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return moment.utc(time).tz(timezone).format('DD/MM/YYYY');
  };

  const handleOnClick = (id: string, status: string, supportType: SupportType) => {
    supportType === SupportType.BookingRequest
      ? navigateToUrl(`/account/support/priority-support-booking/${id}`)
      : navigateToUrl(`/account/support/support-requests/${id}`);
    GtmManager.dataLayer({
      dataLayer: {
        event: 'view_support_request_details',
        request_status: status,
        page_index: 0,
      },
      dataLayerName: 'MyAccount',
    });
  };

  const statusLabel = (label) => {
    return (
      <Label type="boxed" tone={STATUS[label]}>
        {label}
      </Label>
    );
  };

  const getZoneIdFromRegion = (region: string): string => {
    return region === Region.NZ ? 'Pacific/Auckland' : 'Australia/Sydney';
  };

  const formatPremiumSupportBookingTimeSubject = (scheduledTime: string): string => {
    const zone = getZoneIdFromRegion(helper.getRegionFromTimezone());
    return 'Priority Support on ' + moment.utc(scheduledTime).tz(zone).format('DD/MM [at] HH:mm');
  };

  const renderRow = (request: SupportRequestInfo) => (
    <Table.Row key={request.id} rowData={request}>
      <Table.RowItem columnName="Subject" width="flex-2">
        {request.supportType == SupportType.BookingRequest
          ? formatPremiumSupportBookingTimeSubject(request.subject)
          : request.subject}
      </Table.RowItem>
      <Table.RowItem columnName="Number" data-testid={`row-item-number-${request.id}`}>
        {request.supportType == SupportType.BookingRequest ? `` : request.caseNumber}
      </Table.RowItem>
      <Table.RowItem columnName="Date created">{timeTransformer(request.created)}</Table.RowItem>
      <Table.RowItem columnName="Last updated">{timeTransformer(request.lastUpdated)}</Table.RowItem>
      <Table.RowItem columnName="Status">{statusLabel(request.status)}</Table.RowItem>
      <Table.RowItem columnName="Requester" width="flex-2">
        {request.supportType ? request.fullName : request.requesterFirstName + '\xa0' + request.requesterLastName}
      </Table.RowItem>
      <Table.RowItem columnName="View">
        <a
          onClick={() => handleOnClick(request.id, request.status, request.supportType)}
          onKeyDown={() => handleOnClick(request.id, request.status, request.supportType)}
        >
          {request.supportType == SupportType.BookingRequest ? `View booking →` : `View request →`}
        </a>
      </Table.RowItem>
    </Table.Row>
  );

  const content = useMemo(() => {
    if (status === HttpStatus.Forbidden) {
      return <Forbidden pageTitle="" title="You don’t have access to the requests" description="" size="small" />;
    }
    if (status && status !== HttpStatus.Unauthorized) {
      return <ErrorComponent pageTitle="" size="small" />;
    }
    if (requestsInfo && requestsInfo.length !== 0) {
      return <Table.Body>{requestsInfo.map(renderRow)}</Table.Body>;
    }
    return <NoData pageTitle="You don't have any recent requests" size="small" />;
  }, [requestsInfo, isError]);

  return (
    <>
      <Table variant="card">
        <Table.Header>
          {columns.map((column: Record<string, unknown>) => (
            <Table.HeaderItem key={column.key} width={column.width} data-testid="header-item">
              {column.description}
            </Table.HeaderItem>
          ))}
        </Table.Header>
        <LoadingWrapper isLoading={isLoading} spinnerSize="medium">
          {content}
        </LoadingWrapper>
      </Table>
    </>
  );
};
