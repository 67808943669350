import { Card, PageHead, PageState } from '@myob/myob-widgets';
import ForbiddenImg from 'assets/images/noAccess.svg';
import { ForbiddenWrapper } from './style';
import React from 'react';

export type Props = {
  pageTitle: string;
  title: string;
  description: string;
  size?: string;
};

export const Forbidden: React.FC<Props> = ({ pageTitle, title, description, size = '' }) => {
  return (
    <ForbiddenWrapper data-testid="forbiddenComponent" className="forbidden-component" data-size={size}>
      <PageHead title={pageTitle} />
      <Card>
        <PageState title={title} description={[description]} image={<img src={ForbiddenImg} alt="Forbidden" />} />
      </Card>
    </ForbiddenWrapper>
  );
};
