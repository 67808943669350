import { Region } from '@my-account/tools';

export type BusinessInfo = {
  accountName: string;
  region: Region;
};

export interface SupportRequestInfo {
  id: string;
  caseNumber: string;
  status: string;
  subject: string;
  created: string;
  lastUpdated: string;
  requesterFirstName: string;
  requesterLastName: string;
  supportType?: SupportType;
  fullName?: string;
}

export enum SupportType {
  BookingRequest = 'Booking Request',
  SupportRequest = 'Support Request',
}
