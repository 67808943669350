import { Button, Label, Text, Box } from '@myob/myob-widgets';
import { SupportTileWrapper } from '../../styles';
import { AccountViewRoutePath } from '../../../../type';
import starIcon from '../../../../assets/images/staricon.svg';

export type TileProps = {
  className?: string;
  onClick: (url: string) => void;
};

const CONTACT_SUPPORT = 'Priority Support';
const SUPPORT_REQUEST_BUTTON = 'Lodge a support request';
const SCHEDULE_SUPPORT_SESSION_BUTTON = 'Book a support session';

const PremiumSupportTileItem: React.FC<TileProps> = ({ onClick, className }) => {
  return (
    <SupportTileWrapper className={className}>
      <Box
        display={'flex'}
        flexDirection={'column'}
        backgroundColor={'backgroundDefault'}
        borderRadius={'md'}
        padding={'md'}
        boxShadow={'strong'}
      >
        <div className="tile-info">
          <div className="tile-info-premium">
            <img className="tile-info-premium-staricon" src={starIcon} alt="Star icon" />
            <div className="tile-info-premium-content">
              <h2 className="tile-info-premium-content__title">
                {CONTACT_SUPPORT}&nbsp;&nbsp;
                <Label type="boxed" tone="brand">
                  BETA
                </Label>
              </h2>
              <div className="tile-info-premium-content__description">
                <Text
                  data-testid="premium-contact-description"
                  className="tile-info-premium-content__description_detail"
                >
                  Included with your AccountRight subscription.
                </Text>
                <Text
                  data-testid="premium-contact-description"
                  className="tile-info-premium-content__description_detail"
                >
                  Get help directly from one of our experts. Book a personalised support session, report a bug or give
                  us feedback.
                </Text>
              </div>
            </div>
          </div>
          <div className="tile-info-premium-btn">
            <Button
              tone={'brand'}
              data-testid="contact-support-btn"
              onClick={() => onClick(AccountViewRoutePath.Support)}
              valign="center"
            >
              {SUPPORT_REQUEST_BUTTON}
            </Button>
            <Button
              tone={'brand'}
              variant={'ghost'}
              data-testid="schedule-support-session-btn"
              onClick={() => onClick(AccountViewRoutePath.ScheduleSupportSession)}
              valign="center"
            >
              {SCHEDULE_SUPPORT_SESSION_BUTTON}
            </Button>
          </div>
        </div>
      </Box>
    </SupportTileWrapper>
  );
};

export default PremiumSupportTileItem;
