import { Card, PageState } from '@myob/myob-widgets';
import noResultsFound from '../../../../assets/images/no-result-found.svg';
import React from 'react';
import { NoDataWrapper } from './style';

export type Props = {
  pageTitle: string;
  size?: string;
};

export const NoData: React.FC<Props> = ({ pageTitle, size = '' }) => {
  return (
    <NoDataWrapper className="no-results-found" data-size={size}>
      <Card>
        <PageState title={pageTitle} image={<img src={noResultsFound} alt="No results found" />} />
      </Card>
    </NoDataWrapper>
  );
};
