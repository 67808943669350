import { AnyAction } from 'redux';
import { configureStore, ThunkDispatch } from '@reduxjs/toolkit';
import { requestListReducer } from '../pages/Dashboard/RequestList/reducer';

export type RootState = ReturnType<typeof store.getState>;

const store = configureStore({
  reducer: {
    requestList: requestListReducer,
  },
  devTools: process.env.NODE_ENV !== 'production',
});

export type AppDispatch = typeof store.dispatch;
export type AppThunkDispatch = ThunkDispatch<RootState, Record<string, unknown>, AnyAction>;

export default store;
