export const colors = {
  storm06: '#F3F4F5',
  storm11: '#E8EAEC',
  storm17: '#DCDFE1',
  storm28: '#C5C9CE',
  storm50: '#969EA7',
  storm72: '#687480',
  storm83: '#515E6D',
  storm100: '#2E3E4F',
  thunder: '#1B2830',
  dusk05: '#F9F5FB',
  dusk70: '#A476C1',
  dusk100: '#8241AA',
  duskDark: '#582c73',
  duskDarker: '#201029',
  azure: '#2B74DF',
  azureLight: '#98BAEC',
  berry: '#CE2554',
  berryLight: '#E796AC',
  golf: '#00AA65',
  golfLight: '#66D7A9',
  pineapple: '#E6C543',
  pineappleLight: '#EBD068',
  crown: '#806D25',
  white: '#ffffff',
};

export const shadow = {
  card: '0 1px 2px 0 rgba(46,62,79,0.10)',
  popover: '0 0 6px 0 rgba(46,62,79,0.25)',
  modal: '0 6px 16px 0 #2E3E4F',
  alert: '0 2px 4px 0 rgba(104,116,128,0.10)',
  aside: '0 0 6px 0 rgba(46,62,79,0.25)',
};

export const mediaPoints = {
  xs: 320,
  sm: 768,
  md: 1024,
  lg: 1200,
};

export const mediaQueries = {
  xSmallOnly: `@media (max-width: ${mediaPoints.xs}px)`, // <= 320px
  smallOnly: `@media (min-width:${mediaPoints.xs + 1}px) and (max-width: ${mediaPoints.sm - 1})px`, // 321px - 767px
  smallUp: `@media (min-width: ${mediaPoints.xs + 1}px)`, // >=321px
  specificUp: `@media (min-width: 500px)`, // Feelix specific break point
  specificDown: `@media (max-width: 500px)`, // Feelix specific break point
  mediumOnly: `@media (min-width: ${mediaPoints.sm}px) and (max-width: ${mediaPoints.md - 1}px)`, // 768px - 1023px
  mediumUp: `@media (min-width: ${mediaPoints.sm}px)`, // >= 768px
  mediumDown: `@media (max-width: ${mediaPoints.sm}px)`, // < 768px
  largeOnly: `@media (min-width:${mediaPoints.md}px) and (max-width: ${mediaPoints.lg - 1}px)`, // 1024px - 1199px
  largeUp: `@media (min-width: ${mediaPoints.md}px)`, // >= 1024px
  xLargeUp: `@media (min-width: ${mediaPoints.lg}px)`, // >= 1200px
  xLargeDown: `@media (max-width: ${mediaPoints.lg}px)`, // >= 1200px
};

export const fontWeight = {
  normal: 400,
  bold: 600,
};

export const fontSize = {
  text: '1.4rem',
  textSmall: '1.2rem',
  button: '1.4rem',
  headingTiny: '1.2rem',
  headingSmall: '1.4rem',
  headingMedium: '1.6rem',
  headingLarge: '1.8rem',
  headingXLarge: '2.4rem',
  paragraphSmall: '1.2rem',
  paragraphLarge: '1.6rem',
};

export const lineHeight = {
  reset: 1,
  text: 1.5,
  heading: 1.25,
};

export const borderRadius = {
  card: '0.4rem',
  tableRowSelected: '0.4rem',
  alert: '0.4rem',
  popover: '0.4rem',
  modal: '0.4rem',
  button: '0.4rem',
  badge: '1.2rem',
  label: '1.2rem',
  tag: '0.2rem',
  input: '0.4rem',
};
