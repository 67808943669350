import { auth, telemetry } from '@my-account/tools';
import Cookies from 'js-cookie';
import { getBusinessId } from 'helpers/query';
import { appConfig } from 'config';

type TrackProperties = {
  userId: string;
  clientId: string;
  businessId: string;
  myobVisitorId: string;
  timestamp: string;
  action: string;
  event: string;
  url: string;
  page: string;
};

export const trackClickEventInDashboard = (
  buttonName: string,
  url: string,
  clientId?: string,
  isHelpLink?: boolean,
): void => {
  const buttonType = isHelpLink ? 'link' : 'menu';
  const eventName = `My Account ${buttonName} ${buttonType} clicked`;
  const segementTrackParam: TrackProperties = {
    userId: auth.getUser().userId,
    clientId: clientId ?? '',
    businessId: getBusinessId(),
    myobVisitorId: Cookies.get('myob_visitor_id') ?? '',
    timestamp: new Date(Date.now()).toISOString(),
    action: `Click ${buttonName.toLowerCase()}`,
    event: eventName,
    url: isHelpLink ? url : `${appConfig.MY_ACCOUNT_BASE_URL}${url}`,
    page: buttonName,
  };
  telemetry.segmentTrack(eventName, segementTrackParam);
};

export const trackPageViewed = (title: string): void => {
  const pageName = `My Account ${title} Page`;

  telemetry.page(pageName, {
    title: title,
    myobVisitorId: Cookies.get('myob_visitor_id') || '',
  });
};
