import { request } from './request';
import { getBusinessId, isAccountSelectPage } from 'helpers/query';
import { Account } from '@my-account/account';
import { BusinessInfo, SupportRequestInfo } from '../pages/Dashboard/type';

export const fetchBusinessInfo = async (): Promise<BusinessInfo> => {
  if (isAccountSelectPage()) {
    const selectedAccount = JSON.parse(sessionStorage.getItem('@my-account/features:selectedAccount'));
    return Promise.resolve(selectedAccount);
  }
  return request<BusinessInfo>(`/api/home?businessId=${getBusinessId()}`);
};

export const fetchSupportRequestsByAccountId = async (clientId: Account['clientId']): Promise<SupportRequestInfo[]> => {
  return request<SupportRequestInfo[]>(`/api/cases?clientId=${clientId}`);
};

export const fetchAggregateSupportByClientId = async (clientId: Account['clientId']): Promise<SupportRequestInfo[]> => {
  return request<SupportRequestInfo[]>(`/api/all-cases?clientId=${clientId}`);
};
