import React from 'react';
import { Provider } from 'react-redux';
import store from './stores';
import { BrowserRouter } from 'react-router-dom';
import { Routes } from './router';
import { ThemeProvider } from '@myob/myob-widgets';
export const Root = () => {
  return (
    <Provider store={store}>
      <ThemeProvider>
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  );
};
