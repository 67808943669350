import React, { useContext } from 'react';
import { navigateToUrl } from 'single-spa';
import { Columns } from '@myob/myob-widgets';
import Tile, { TileProps } from 'components/Tile';
import paymentDetailsImg from 'assets/images/payment-details.svg';
import myBillsImg from 'assets/images/my-bills.svg';
import productImg from 'assets/images/product.svg';
import accountDetailsImg from 'assets/images/account-details.svg';
import { AccountViewRoutePath, RoutePath } from 'type';
import { TileListWrapper } from 'pages/Dashboard/styles';
import { checkSoloAccount, isAccountSelectPage } from 'helpers/query';
import SupportTileItem from './SupportTileItem';
import { trackClickEventInDashboard } from 'helpers/telemetry';
import { AccountSelectContext, AccountSelectContextValue } from '@my-account/account';

const getTileData = (): Omit<TileProps, 'onClick' | 'target'>[] => {
  const accountSelectPage = isAccountSelectPage();

  return [
    {
      title: 'Products',
      description: 'View your product inclusions and manage your subscription here.',
      image: productImg,
      link: accountSelectPage ? AccountViewRoutePath.Product : RoutePath.Product,
      linkText: 'Manage my product',
    },
    {
      title: 'Payment details',
      description: 'View or update your payment methods for your account.',
      image: paymentDetailsImg,
      link: accountSelectPage ? AccountViewRoutePath.PaymentDetails : RoutePath.PaymentProfile,
      linkText: 'View payment details',
    },
    {
      title: 'Bills',
      description: 'View and pay bills for your subscriptions and purchases.',
      image: myBillsImg,
      link: accountSelectPage ? AccountViewRoutePath.MyBills : RoutePath.MyBills,
      linkText: 'View my bills',
    },
    {
      title: 'Account details',
      description: 'View your account details and primary billing contact.',
      image: accountDetailsImg,
      link: AccountViewRoutePath.AccountDetails,
      linkText: 'Manage my account',
    },
  ];
};

type Props = {
  clientId?: string;
};

export const TileList: React.FC<Props> = ({ clientId }) => {
  const { isSoloAccount } = useContext<AccountSelectContextValue>(AccountSelectContext);

  const handleTileClick = (url: string, linkText?: string) => {
    navigateToUrl(url);
    linkText && trackClickEventInDashboard(linkText, url, clientId);
  };
  return (
    <TileListWrapper>
      <Columns className="tile-list-columns">
        {getTileData().map((tile) => (
          <Tile className="tile-item" key={tile.title} onClick={handleTileClick} {...tile} />
        ))}
      </Columns>

      {!checkSoloAccount(isSoloAccount) && (
        <Columns className="tile-list-columns">
          <Columns.Column span={'12'} className="tile-list-columns__column">
            <SupportTileItem className="tile-item" key="Contact support" onClick={handleTileClick} />
          </Columns.Column>
        </Columns>
      )}
    </TileListWrapper>
  );
};
