export const tableColumns = [
  { key: 'subject', description: 'Subject', visible: true, width: 'flex-2' },
  { key: 'number', description: 'Number', visible: true, width: 'flex-1' },
  { key: 'dateCreated', description: 'Date created', visible: true, width: 'flex-1' },
  { key: 'lastUpdated', description: 'Last updated', visible: true, width: 'flex-1' },
  { key: 'status', description: 'Status', visible: true, width: 'flex-1' },
  { key: 'requester', description: 'Requester', visible: true, width: 'flex-2' },
  { key: 'view', description: 'View', visible: true, width: 'flex-1' },
];

export const STATUS: Record<string, string> = {
  New: 'success',
  'In progress': 'info',
  'Action required': 'warning',
  Closed: 'danger',
};
